<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="1050px">
        <v-card>
            <v-card-title>
                <span>{{item.name}}: {{parseFloat(total_quantity).toFixed(2)}}/{{item.required_quantity}} {{item.unit}} Completados</span>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="canasta"
                    :items-per-page="5"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    class="elevation-1 datatable"
                >
                    <template v-slot:item.actions="{ item }" >
                        <td class="text-center">
                            <v-btn @click="eliminarFila(item)" text icon color="red">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </td>
                    </template>
                </v-data-table>
                <h3 class="title">Agregar una fila:</h3>
                <v-form >
                    <!--<v-container>-->
                        <v-row>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-combobox
                                    :items="suppliersList"
                                    v-model="newCanastaItem.supplier_id"
                                    label="Proveedor"
                                    ref="vselect"
                                ></v-combobox>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-text-field
                                    v-model="newCanastaItem.product_name"
                                    :disabled="true"
                                    label="Producto"
                                    required
                                ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            md="3"
                            >
                            <v-text-field
                                v-model="newCanastaItem.quantity"
                                type="number"
                                min="0"
                                step="0.05"
                                label="Cantidad comprada"
                                required
                                :suffix="item.unit"
                            ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                            <v-text-field
                                v-model="newCanastaItem.price"
                                type="number"
                                min="0"
                                step="1"
                                label="Precio"
                                required
                                :suffix="'Pesos'"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-text-field
                                    v-model="newCanastaItem.invoice_number"
                                    label="N° factura"
                                    
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-text-field
                                    v-model="newCanastaItem.delivery_receipt"
                                    label="N° guia despacho"
                                    
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-text-field
                                    v-model="newCanastaItem.merchandise_format"
                                    label="Formato mercadería"
                                    
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            class="d-flex align-center"
                            >
                                <v-btn block @click="agregarFila" :disabled="!newCanastaItem.quantity || !newCanastaItem.product_name || !newCanastaItem.supplier_id || total_quantity >= item.quantity || !newCanastaItem.price || newCanastaItem.price < 0 || !newCanastaItem.merchandise_format"  color="cyan" dark 
                                >Agregar <v-icon right dark>mdi-plus</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    <!--</v-container>-->
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            item: {},
            suppliersList: [],
            selectSupplier: undefined,
            canasta: [],
            headers: [
                {text: 'Proveedor', value: 'supplier_name' },
                {text: 'Producto', value: 'product_name' },
                {text: 'Cantidad comprada', value: 'purchased_quantity' },
                {text: 'Precio', value: 'price' },
                {text: 'N° factura', value:'invoice_number'},
                {text: 'N° Guia despacho', value: 'delivery_receipt'},
                {text: 'Formato mercadería', value: 'merchandise_format'},
                {text: '', value: 'actions' },
            ],
            newCanastaItem: {
                supplier_id: undefined,
                supplier_name: undefined,
                product_name: undefined,
                product_id: undefined,
                quantity: 0.0,
                invoice_number: '',
                delivery_receipt: '',
                price: 0,
                merchandise_format: undefined,
            },
            products_completed: 0,
            indexItem: undefined,
        }
    },
    created(){
        /*
        var vm = this;
        this.axios({
            url: 'admin/permissions',
            method: 'GET',
        }).then( response => {
            vm.permissionsList = response.data;
        }).catch( error => {
            console.log(error);
        });
        */
        this.suppliersList = [
            {text: 'Proveedor 1', value: 'Proveedor 1'},
            {text: 'Proveedor 2', value: 'Proveedor 2'},
            {text: 'Proveedor 3', value: 'Proveedor 3'},
        ]
        var vm = this;
        this.axios({
            url: 'inventory/suppliers_data_list',
            method: 'POST',
            data: {
                store: this.$store.state.passport.user.default_store_id
            }
        }).then( response => {
            vm.suppliersList = response.data;
        }).catch( error => {
            console.log(error);
        });
        this.$parent.$on('openDialogCompletadoDelItem', (data) => {
            console.log('Hola pase por acá');
            this.newCanastaItem.product_name = data.item.name;
            this.item = data.item;
            this.indexItem = data.index;
            this.canasta = data.item.basket;
            this.showDialog = true;     
        });
    },
    methods: {
        agregarFila(){
            this.canasta.push({
                supplier_name: this.$refs.vselect.selectedItems[0].text,
                supplier_id: this.newCanastaItem.supplier_id.value,
                price: this.newCanastaItem.price,
                invoice_number: this.newCanastaItem.invoice_number,
                delivery_receipt: this.newCanastaItem.delivery_receipt,
                product_name: this.newCanastaItem.product_name,
                purchased_quantity: this.newCanastaItem.quantity,
                merchandise_format: this.newCanastaItem.merchandise_format,
            })
        },
        eliminarFila(item){
            const index = this.canasta.indexOf(item);
            this.canasta.splice(index,1);
        },
        getItemText(supplier_name){
            this.newCanastaItem.supplier_name = supplier_name;
        },
        sendForm(){
            console.log('sendForm');
            this.$emit('finish', {basket: this.canasta, total_quantity: this.total_quantity, index: this.indexItem});
            this.canasta = [];
            this.showDialog = false;
        }
    },
    computed: {
        total_quantity(){
            if( this.canasta.length ){
                let acum = 0.0;
                this.canasta.forEach( elem => {
                    acum += parseFloat(elem.purchased_quantity);
                });
                return acum;
            }else{
                return 0;
            }
        }
    }
}
</script>