<template>
    <div>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Crear Orden de compra
                        <v-spacer></v-spacer>
                        <v-btn class="" @click="fetchData"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <h3 class="title">Seleccione los pedidos:</h3>
                        <div class="datepicker-trigger">
                            <!--
                            <input
                                type="text"
                                id="datepicker-trigger"
                                placeholder="Select dates"
                                :value="formatDates(dateOne, dateTwo)"
                            >-->
                            <v-text-field
                                
                                id="datepicker-trigger"
                                placeholder="Filtrar por rango de fechas"
                                :value="formatDates(dateOne, dateTwo)"
                            />
                            <AirbnbStyleDatepicker
                                :trigger-element-id="'datepicker-trigger'"
                                :mode="'range'"
                                :fullscreen-mobile="true"
                                :date-one="dateOne"
                                :date-two="dateTwo"
                                @date-one-selected="val => { dateOne = val }"
                                @date-two-selected="val => { dateTwo = val }"
                            />
                        </div>
                        <v-data-table
                            :headers="headersOrders"
                            :items="tablaDataOrders"
                            :search="searchOrders"
                            :single-select="false"
                            show-select
                            v-model="selectedOrders"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            :sort-by="'id'"
                            :sort-desc="true"
                            class="elevation-1 datatable"
                        >
                            <template v-slot:item.ingreso="{ item }">
                                
                                <td class="text-center">
                                    {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                                </td>
                                
                            </template>
                        </v-data-table>
                        <br><br>
                        <v-btn @click="startIngresarItems" v-show="selectedOrders.length" small color="info" dark class="float-left"
                        ><v-icon left dark>mdi-format-list-checks</v-icon>Crear orden de compra con los pedidos seleccionados</v-btn>
                        <v-btn @click="startIngresarItemsAll" v-show="!selectedOrders.length" small color="orange" dark class="float-left"
                        ><v-icon left dark>mdi-format-list-checks</v-icon>Crear orden de compra con todos los pedidos</v-btn>
                        <br><br>
                        <!--
                        <v-container v-show="itemsOrden.length">
                            <h3 class="title">Ingrese items en cada producto:</h3>
                            <v-sheet
                                class="mx-auto"
                                elevation="8"
                                
                            >
                                <v-slide-group
                                v-model="modelGroup"
                                class="pa-4"
                                active-class="success"
                                show-arrows
                                >
                                <v-slide-item
                                    v-for="(item, index) in itemsOrden"
                                    :key="index"
                                    
                                >
                                    <v-card
                                    class="mx-2"
                                    max-width="344"
                                    outlined
                                    >
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <div class="overline mb-4">Producto #{{item.id}}</div>
                                                <v-list-item-title class="headline mb-1">{{item.name}}</v-list-item-title>
                                                <v-list-item-subtitle>Requerido: {{item.required_quantity}} {{item.unit}}</v-list-item-subtitle>
                                                <v-list-item-subtitle>Cantidad restante: <span :class="parseFloat(item.left) > 0 ? 'red--text': 'green--text'">{{parseFloat(item.left).toFixed(2)}}</span> {{item.unit}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-card-actions>
                                            <v-btn  outlined color="success" @click="meTocaste(item)" text>Ingresar items</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    -->
                                    <!--
                                    <v-card
                                    :color="'grey lighten-1'"
                                    class="ma-4"
                                    height="200"
                                    width="200"
                                    @click="meTocaste(item)"
                                    >
                                    <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-scale-transition>
                                        <h4>Cantidad comprada: {{item.purchased_quantity}} {{item.unit}}</h4>
                                        </v-scale-transition>
                                    </v-row>
                                    </v-card>
                                    -->
                                    <!--
                                </v-slide-item>
                                </v-slide-group>
                            </v-sheet>
                            <v-btn @click="finishIngresarItems" v-show="itemsOrden.length" small color="info" dark class="float-left"
                                ><v-icon left dark>mdi-format-list-checks</v-icon>Guardar</v-btn>
                        </v-container>
                        -->
                    </v-card-text>
                </v-card>
            </div>
        </div>
        
    </v-app>
    <completador-del-item @finish="recibirCanasta"></completador-del-item>
    </div>
</template>

<script>
import CompletadorDelItem from '@/views/oms/Compras/CompletadorDelItem.vue';
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    components: {
        CompletadorDelItem
    },
    data(){
        return {
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            range: {},
            headersOrders: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Fecha de ingreso', value: 'ingreso', filter: this.filtroRangoFechas },
            ],
            tablaDataOrders: [

            ],
            searchOrders: '',
            selectedOrders: [],
            modelGroup: null,
            itemsOrden: [
                /*
                {id: 1, name: 'Tomates', required_quantity: 3, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                {id: 2, name: 'Paltas', required_quantity: 3, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                {id: 3, name: 'Papas', required_quantity: 3, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                {id: 4, name: 'Frutillas', required_quantity: 1, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                {id: 5, name: 'Zanahorias', required_quantity: 3, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                {id: 6, name: 'Duraznos', required_quantity: 3, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                {id: 7, name: 'Manzana', required_quantity: 3, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                {id: 8, name: 'Pera', required_quantity: 3, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                {id: 9, name: 'Banana', required_quantity: 3, unit: 'Kg', purchased_quantity: 0, left: 3, basket: []},
                */
            ]
        }
    },
    methods: {
        meTocaste(item)
        {
            console.log('ha sido tocado: ',item);
            const index = this.itemsOrden.indexOf(item)
            this.$emit('openDialogCompletadoDelItem', {item, index});
        },
        recibirCanasta(data)
        {
            console.log('recibi: ', data);
            // Actualizando data del item desde el componente hijo dialog
            this.itemsOrden[data.index].purchased_quantity = parseFloat(data.total_quantity);
            this.itemsOrden[data.index].left = this.itemsOrden[data.index].required_quantity - data.total_quantity;
            this.itemsOrden[data.index].basket = data.basket;
        },
        fetchDataOrders()
        {
            var vm = this;
            this.axios({
                url: 'inventory/orders',
                method: 'GET'
            }).then( response => {
                vm.tablaDataOrders = response.data.data;
            }).catch( error => {
                console.log(error);
            });
        },
        startIngresarItemsAll()
        {
            this.selectedOrders = this.tablaDataOrders;
            this.startIngresarItems();
        },
        startIngresarItems()
        {
            console.log('comenzar a ingresar items');
            var vm = this;
            this.axios({
                url: 'inventory/start_order_purchase',
                method: 'POST',
                data: {
                    orders: this.selectedOrders,
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response => {
                vm.fetchDataOrders();
                vm.axios({
                    url: 'inventory/fetch_purchase_order_details',
                    method: 'POST',
                    data: {
                        purchase_order: response.data.purchase_order_id,
                        store: vm.$store.state.passport.user.default_store_id
                    }
                }).then( response2 => {
                    vm.selectedOrders = [];
                    vm.itemsOrden = [];
                    
                    response2.data.tableData.forEach(element => {
                        let purchased_quantity = 0.0;
                        element.basket.forEach( ylymynt => {
                            purchased_quantity+= ylymynt.purchased_quantity;
                        });
                        vm.itemsOrden.push({
                            basket: element.basket,
                            id: element.id,
                            //left: element.required,
                            left: parseFloat(element.required) - purchased_quantity,
                            name: element.name,
                            //purchased_quantity: 0,
                            purchased_quantity: purchased_quantity,
                            required_quantity: element.required,
                            unit: element.unit.name
                        });
                    });
                    vm.$router.push({name: 'ordenes de compra'});
                }).catch( error2 => {
                    console.log(error2);
                });
                
            } ).catch( error => {
                console.log(error);
            });
        },
        filtroRangoFechas(value, search, item){
            console.log('item', item);
            console.log('value', value);
            if( !this.dateOne || !this.dateTwo ){
                return true;
            }else{
                if( this.$moment(item.created_at).isSameOrAfter(this.$moment(this.dateOne)) && this.$moment(item.created_at).isSameOrBefore(this.$moment(this.dateTwo)) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        finishIngresarItems()
        {
            var vm = this;
            this.axios({
                url: 'inventory/finish_order_purchase',
                method: 'POST',
                data: {
                    items: this.itemsOrden
                }
            }).then( response => {
                
                vm.$router.push({
                    name: "ordenes de compra"
                });
            }).catch( error => {
                console.log(error);
            });
        },
    },
    mounted()
    {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Crear orden de compra", route: "/compras/ordenes/crear" },
        ]);
        this.fetchDataOrders();
    }
}
</script>